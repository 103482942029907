import {FunctionalComponent, h} from "@stencil/core";

export interface TrHelperProps {
  values: any[]
  addTd: boolean
  addTr: boolean
}

export const TrHelper: FunctionalComponent<TrHelperProps> = ({ values, addTd = true, addTr = true}) => {
  var ar = []

  for (let i = 0; i < values.length; i++) {
    if (addTd)
      ar.push(<td>{values[i]}</td>)
    else
      ar.push(values[i])
  }
  if (addTr) {
    return <tr>{ar}</tr>
  }
  return ar;
};

export enum TrStrategy {
  VALUE_ONLY,
  VALUE_AND_TD,
  VALUE_AND_TR
}

export interface TrHelperStrategyProps {
  values: any[]
  strategy: TrStrategy
}
export const TrHelperStrategy: FunctionalComponent<TrHelperStrategyProps> = ({ values, strategy}) => {
  return <TrHelper values={values} addTd={strategy == TrStrategy.VALUE_AND_TD || strategy == TrStrategy.VALUE_AND_TR} addTr={strategy == TrStrategy.VALUE_AND_TR} />
};
