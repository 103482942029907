import {Component, h, Host, Prop} from '@stencil/core';
import {LocalTime} from "../../../utils/timeUtils";
import {TrHelperStrategy, TrStrategy} from "../functional-components/tr-helper";

@Component({
  tag: 'time-picker',
  styleUrl: 'time-picker.css',
  shadow: true,
})
export class TimePicker {
  @Prop()
  onChange: (time:LocalTime) => void;
  @Prop()
  label: string
  @Prop()
  startValue: string
  @Prop()
  tr: TrStrategy = TrStrategy.VALUE_ONLY

   render() {
     let values = TimePicker.getComponentsValues(this.label, this.startValue, false, this.onChange);

     if (this.tr == TrStrategy.VALUE_ONLY)
       return (
         <Host>
           <TrHelperStrategy values={values} strategy={this.tr} />
         </Host>
       );

       return <TrHelperStrategy values={values} strategy={this.tr} />
  }

  public static getComponentsValues(label: string, value: string, required: boolean, onChange: (time: LocalTime) => void) {
    return [label ? <label>{label}{required ? (value ? " *" : <span style={{color: "red"}}> *</span>) : ""}</label> : "", <input type={"time"} value={value} onChange={(event: any) => {
      if (onChange) onChange(new LocalTime(event.target.value))
    }}/>]
  }
}
